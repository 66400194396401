import { useHasNewAppVersion } from '@/components/app-version-updater/has-new-app-version';
import {
  LinkProps,
  NavLinkProps,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Link as RRLink,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  NavLink as RRNavLink,
} from 'react-router';
import { forwardRef } from 'react';

export type { LinkProps, NavLinkProps } from 'react-router';

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  function Link(props, ref) {
    const hasNewAppVersion = useHasNewAppVersion();

    return <RRLink ref={ref} reloadDocument={hasNewAppVersion} {...props} />;
  },
);

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  function Link(props, ref) {
    const hasNewAppVersion = useHasNewAppVersion();

    return <RRNavLink ref={ref} reloadDocument={hasNewAppVersion} {...props} />;
  },
);
